<template>
  <div class="estate-base-info-tab y">
    <el-tabs class="f-tabs" v-model="activeName">
      <el-tab-pane
        v-for="(item, index) in tabs"
        :label="item.name"
        :name="`${index}`"
        :key="item.component"
      ></el-tab-pane>
    </el-tabs>
    <div class="wrap">
      <div class="f-container">
        <component :is="tabs[activeName].component"></component>
      </div>
    </div>
  </div>
</template>

<script>
import comment from "./_components/comment";
import question from "./_components/question";
export default {
  components: {
    comment,
    question
  },
  data() {
    return {
      tabs: [
        {
          name: "楼盘评论",
          component: "comment"
        },
        {
          name: "楼盘问答",
          component: "question"
        }
      ],
      activeName: 0
    };
  },
  created() {}
};
</script>
<style lang="scss">
.estate-base-info-tab {
  height: 100%;
  .f-tabs {
    width: calc(100% + 40px);
    margin: -22px 0 0 -20px;
    position: absolute;
    z-index: 2;
    .el-tabs__header {
      background: #fff;
      .el-tabs__nav {
        margin-left: 20px;
      }
    }
  }
}
</style>

<style scoped lang="scss">
.estate-base-info-tab {
  .wrap {
    margin-top: 60px;
    padding-bottom: 20px;
  }
}
.f-container {
  padding: 30px;
}
</style>
