<template>
  <div class="estate-comment">
    <div class="filter">
      <el-input
        placeholder="问题关键词、发布者昵称"
        v-model="queryData.title"
        style="width: 320px;"
        class="f-search-bar"
      >
        <el-button slot="append" @click="search">搜索</el-button>
      </el-input>
    </div>
    <el-table border class="body" :data="list">
      <el-table-column label="提问内容" prop="content">
        <template v-slot="{ row }">
          <div class="title" v-html="row.content.replace(/\n/g, '<br>')"></div>
        </template>
      </el-table-column>
      <el-table-column label="渠道来源">
        <template v-slot="{ row }">
          <template v-if="row.terminalSource == 4">
            <div class="title">房猫CMS</div>
            <p>WEB端</p>
          </template>
          <template v-else>
            <div class="title">
              {{ row.platformName || "-" }}
            </div>
            <p>{{ terminalSourceMaps[row.terminalSource] || "-" }}</p>
          </template>
        </template>
      </el-table-column>
      <el-table-column label="发布者">
        <template v-slot="{ row }">
          <div class="title">{{ row.customerName }}</div>
          <p v-if="row.authSlaveType">
            {{ authSlaveTypeMaps[row.authSlaveType].label }}
          </p>
        </template>
      </el-table-column>
      <el-table-column label="提问时间" prop="inDate">
        <template v-slot="{ row }">
          <div class="title">{{ row.inDate }}</div>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="200px">
        <template v-slot="{ row }">
          <el-popover
            :ref="`popover${row.trendsId}`"
            placement="top-end"
            width="365"
            v-model="row.showDiscuss"
          >
            <discuss
              :count="row.commentCount"
              :bizId="row.trendsId"
              :show="row.showDiscuss"
              :commentType="4"
              @refresh="getList"
            ></discuss>
          </el-popover>
          <el-button
            slot="reference"
            type="text"
            v-if="row.commentCount"
            v-popover:[`popover${row.trendsId}`]
            >讨论({{ row.commentCount }})
          </el-button>
          <span class="separate" v-if="row.commentCount">|</span>
          <el-popconfirm
            title="确定删除该评论？"
            @onConfirm="deleteTrends(row.trendsId)"
          >
            <el-button slot="reference" type="text">删除</el-button>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="pageSizeChange"
      @current-change="pageNumChange"
      :current-page.sync="queryData.pageNum"
      :page-sizes="pageSizes"
      :page-size="queryData.pageSize"
      layout="sizes, prev, pager, next,jumper"
      :total="commentTotal"
    >
    </el-pagination>
  </div>
</template>

<script>
import Discuss from "../../../_components/discuss";
import { trendsList, deleteTrends } from "@/api/estate";
import { enumeration } from "@/config";
import { link } from "@/utils/index";
export default {
  components: { Discuss },
  data() {
    return {
      terminalSourceMaps: enumeration.terminalSourceMaps, // 终端来源：1-平台小程序  4-后台  7-IOS  8-Android
      authSlaveTypeMaps: enumeration.authSlaveTypeMaps, // 认证类型：1-地产达人,2-置业顾问,3-PCN经纪
      pageSizes: [10, 20, 30, 50],
      commentTotal: 0,
      list: [], //评论列表
      queryData: {
        keyword: "",
        trendsType: 5,
        pageNum: 1,
        pageSize: 10
      }
    };
  },
  computed: {
    estateId() {
      return this.$store.state.estateInfo.estateId;
    },
    userId() {
      return this.$store.state.userInfo.userId;
    }
  },
  created() {
    this.getList();
  },
  methods: {
    link,
    search() {
      this.queryData.pageNum = 1;
      this.getList();
    },
    async getList() {
      // 动态列表
      const res = await trendsList({
        ...this.queryData,
        estateId: this.estateId,
        userId: this.userId
      });
      if (res) {
        this.list = res.list.map(item => {
          return {
            ...item,
            showDiscuss: false
          };
        });
      }
    },
    async deleteTrends(trendsId) {
      console.log(trendsId);
      // 删除动态
      const res = deleteTrends({ trendsId });
      if (res) {
        this.$showSuccess("删除成功");
        this.getList();
      }
    },
    //页面条数
    pageSizeChange(val) {
      this.queryData.pageSize = val;
      this.getList();
    },
    //当前页面
    pageNumChange(val) {
      this.queryData.pageNum = val;
      this.getList();
    }
  }
};
</script>
<style scoped lang="scss">
.estate-comment {
  min-height: 100%;
  .head {
    .title {
      h3 {
        font-size: 16px;
        line-break: 16px;
        padding-left: 20px;
        border-left: 3px solid $primary;
      }
      p {
        margin-top: 10px;
        padding-left: 20px;
        color: $color9;
      }
    }
  }
  .body {
    margin-top: 15px;
    p {
      color: #aaa;
    }
    .separate {
      margin: 0 10px;
      color: #aaa;
    }
    .el-button {
      padding: 0;
    }
  }
  ::v-deep .body.el-table td {
    vertical-align: top;
  }
}
.filter {
  display: flex;
  justify-content: space-between;
}
</style>
