var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('el-dialog', _vm._g(_vm._b({
    staticClass: "f-dialog",
    attrs: {
      "title": _vm.formData.id ? '编辑评论' : '创建评论',
      "width": "500px"
    }
  }, 'el-dialog', _vm.$attrs, false), _vm.$listeners), [_c('el-form', {
    ref: "form",
    staticClass: "form",
    attrs: {
      "model": _vm.formData,
      "label-width": "120px"
    }
  }, [_c('el-form-item', {
    attrs: {
      "label": "用户头像",
      "prop": "customerAvatarPath",
      "rules": [_vm.rules.required()]
    }
  }, [_c('div', {
    staticClass: "avatar-wrap"
  }, [_c('el-image', {
    staticClass: "avatar",
    attrs: {
      "fit": "cover",
      "src": _vm.formData.customerAvatarPath
    },
    on: {
      "error": _vm.getRandomAvatar
    }
  }), _c('svg-icon', {
    staticClass: "change-btn",
    attrs: {
      "icon-class": "reset"
    },
    on: {
      "click": _vm.getRandomAvatar
    }
  })], 1)]), _c('el-form-item', {
    attrs: {
      "label": "用户昵称",
      "prop": "customerName",
      "rules": [_vm.rules.required()]
    }
  }, [_c('el-input', {
    attrs: {
      "placeholder": "请输入",
      "maxlength": "20"
    },
    model: {
      value: _vm.formData.customerName,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "customerName", $$v);
      },
      expression: "formData.customerName"
    }
  })], 1), _c('el-form-item', {
    attrs: {
      "label": "评论内容",
      "prop": "commentContent",
      "rules": [_vm.rules.required()]
    }
  }, [_c('el-input', {
    attrs: {
      "type": "textarea",
      "rows": 6,
      "placeholder": "请输入"
    },
    model: {
      value: _vm.formData.commentContent,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "commentContent", $$v);
      },
      expression: "formData.commentContent"
    }
  })], 1), _c('el-form-item', {
    attrs: {
      "label": "发布时间",
      "prop": "date",
      "rules": [_vm.rules.required()]
    }
  }, [_c('el-date-picker', {
    staticStyle: {
      "width": "100%"
    },
    attrs: {
      "clearable": "",
      "type": "datetime",
      "value-format": "yyyy-MM-dd HH:mm:ss",
      "format": "yyyy-MM-dd HH:mm:ss",
      "placeholder": "评论发布日期"
    },
    model: {
      value: _vm.formData.date,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "date", $$v);
      },
      expression: "formData.date"
    }
  })], 1)], 1), _c('div', {
    attrs: {
      "slot": "footer"
    },
    slot: "footer"
  }, [_c('el-button', {
    on: {
      "click": _vm.resetForm
    }
  }, [_vm._v("取 消")]), _c('el-button', {
    attrs: {
      "type": "primary"
    },
    on: {
      "click": _vm.submit
    }
  }, [_vm._v("确 定")])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }