<template>
  <div class="estate-comment">
    <div class="filter">
      <el-input
        placeholder="评论关键词、发布者昵称"
        v-model="queryData.title"
        style="width: 320px;"
        class="f-search-bar"
      >
        <el-button slot="append" @click="search">搜索</el-button>
      </el-input>
      <el-button type="primary" icon="el-icon-plus" @click="toggleDialog = true"
        >创建评论</el-button
      >
    </div>
    <el-table border class="body" :data="list">
      <el-table-column label="评论内容" prop="commentContent">
        <template v-slot="{ row }">
          <div
            class="title"
            v-html="row.commentContent.replace(/\n/g, '<br>')"
          ></div>
        </template>
      </el-table-column>
      <el-table-column label="渠道来源">
        <template v-slot="{ row }">
          <template v-if="row.terminalSource == 4">
            <div class="title">房猫CMS</div>
            <p>WEB端</p>
          </template>
          <template v-else>
            <div class="title">
              {{ row.platformName || "-" }}
            </div>
            <p>{{ terminalSourceMaps[row.terminalSource] || "-" }}</p>
          </template>
        </template>
      </el-table-column>
      <el-table-column label="发布者">
        <template v-slot="{ row }">
          <div class="title">{{ row.customerName }}</div>
          <p>{{ authSlaveTypeMaps[row.customerAuthType] }}</p>
        </template>
      </el-table-column>
      <el-table-column label="发布时间" prop="inDate">
        <template v-slot="{ row }">
          <div class="title">{{ row.inDate }}</div>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="200px">
        <template v-slot="{ row }">
          <el-popover
            :ref="`popover${row.id}`"
            placement="top-end"
            width="365"
            v-model="row.showDiscuss"
          >
            <discuss
              :count="row.commentCount"
              :bizId="row.id"
              :show="row.showDiscuss"
              :commentType="6"
              @refresh="getCommentList"
            ></discuss>
          </el-popover>
          <el-button
            slot="reference"
            type="text"
            v-if="row.commentCount"
            v-popover:[`popover${row.id}`]
            >讨论({{ row.commentCount }})
          </el-button>
          <span class="separate" v-if="row.commentCount">|</span>
          <el-popconfirm
            title="确定删除该评论？"
            @onConfirm="deleteComment(row.id)"
          >
            <el-button slot="reference" type="text">删除</el-button>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="pageSizeChange"
      @current-change="pageNumChange"
      :current-page.sync="queryData.pageNum"
      :page-sizes="pageSizes"
      :page-size="queryData.pageSize"
      layout="sizes, prev, pager, next,jumper"
      :total="commentTotal"
    >
    </el-pagination>
    <edit :visible.sync="toggleDialog" @refresh="getCommentList"></edit>
  </div>
</template>

<script>
import Discuss from "../../../_components/discuss";
import Edit from "./_components/edit";
import { getCommentList, deleteComment } from "@/api/estate";
import { enumeration } from "@/config";
import { link } from "@/utils/index";
export default {
  components: { Discuss, Edit },
  data() {
    return {
      terminalSourceMaps: enumeration.terminalSourceMaps, // 终端来源：1-平台小程序  4-后台  7-IOS  8-Android
      authSlaveTypeMaps: enumeration.authSlaveTypeMaps, // 认证类型：1-地产达人,2-置业顾问,3-PCN经纪
      pageSizes: [10, 20, 30, 50],
      commentTotal: 0,
      list: [], //评论列表
      toggleDialog: false,
      queryData: {
        title: "",
        //请求数据
        pageNum: 1,
        pageSize: 10
      }
    };
  },
  computed: {
    estateId() {
      return this.$store.state.estateInfo.estateId;
    }
  },
  created() {
    this.getCommentList();
  },
  methods: {
    link,
    search() {
      this.queryData.pageNum = 1;
      this.getCommentList();
    },
    //页面条数
    pageSizeChange(val) {
      this.queryData.pageSize = val;
      this.getCommentList();
    },
    //当前页面
    pageNumChange(val) {
      this.queryData.pageNum = val;
      this.getCommentList();
    },
    //删除评论
    async deleteComment(id) {
      const res = await deleteComment({ id });
      res && this.getCommentList();
    },
    //获取评论列表
    async getCommentList() {
      const res = await getCommentList({
        ...this.queryData,
        estateId: this.estateId
      });
      if (res) {
        this.list = res.list.map(item => {
          return {
            ...item,
            showDiscuss: false
          };
        });
        this.commentTotal = res.total;
      }
    }
  }
};
</script>
<style scoped lang="scss">
.estate-comment {
  min-height: 100%;
  .head {
    .title {
      h3 {
        font-size: 16px;
        line-break: 16px;
        padding-left: 20px;
        border-left: 3px solid $primary;
      }
      p {
        margin-top: 10px;
        padding-left: 20px;
        color: $color9;
      }
    }
  }
  .body {
    margin-top: 15px;
    p {
      color: #aaa;
    }
    .separate {
      margin: 0 10px;
      color: #aaa;
    }
    .el-button {
      padding: 0;
    }
  }
  ::v-deep .body.el-table td {
    vertical-align: top;
  }
}
.filter {
  display: flex;
  justify-content: space-between;
}
</style>
